<template>
    <div class="board-container">
        <div role="table" class="board-table board-table--input">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">제목</div>
                    <div role="cell">
                        <div class="form-input">
                            <input class="input" type="text" v-model="board.subject" @input="$emit('input', board)">
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <naver-smarteditor v-model="board.content" @input="$emit('input', board)"></naver-smarteditor>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">썸네일 이미지</div>
                    <div role="cell">
                        <div >
                            <label class="file" for="thumb">
                                <input id="thumb" class="input" type="file" @change="$event => {board.thumb = $event.target.files[0]; $emit('input', board) }">
                                <span class="text"><span v-if="board.thumb">{{ board.thumb.name }}</span></span>
                                <span class="button button--primary">파일선택</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 버튼 모바일에서 가운데 정렬 -->
        <div class="board-buttons board-buttons--right-center">
            <!-- <div class="left">
                <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>삭제</span></a>
                <a class="button button--border" href=""><span>답글</span></a>
            </div> -->
            <div class="right">
                <a class="button button--border" @click="$router.go(-1)"><span>취소</span></a>
                <a class="button button--primary" @click="$emit('save')"><span>확인</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
	components: {
        NaverSmarteditor
	},
    props: {
        value: { type: Object, default: null }
    },
    data(){
        return {
            board: this.$props.value
        };
    },
    watch: {
        value(){
            this.board = this.value;
        }
    }
}
</script>
